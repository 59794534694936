
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class FormId extends Vue {
  loading: boolean = false;
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "contentform",
  });

  async created() {
    await this.dataSource.get();
  }
}
